import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import AppLayoutAdmin from "../views/AppLayoutAdmin";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Pool', component: () => import('@/views/Dashboard/Pool') },
  { path: '/pools/:playlist_hash', name: 'PoolPlaylist', component: () => import('@/views/Dashboard/Pool') },

  { path: '/login', name: 'Login', component: () => import('@/views/Auth/Login') },
  { path: '/register', name: 'Register', component: () => import('@/views/Auth/Register') },

  { path: '/songs', meta: { access_level: 1 }, component: () => import('@/views/Dashboard/Songs') },
  { path: '/artist/upload/song', component: () => import('@/views/Dashboard/Upload') },

  { path: '/admin', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Admin/Home', component: () => import('@/views/Admin/Home') },
  { path: '/admin/users', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Users', component: () => import('@/views/Admin/Users') },
  { path: '/admin/users/:user_id', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'User', component: () => import('@/views/Admin/User') },
  { path: '/admin/playlists', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Playlists', component: () => import('@/views/Admin/Playlists/List') },
  { path: '/admin/songs', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Admin/Songs', component: () => import('@/views/Admin/Songs/List') },
  { path: '/songs/upload', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: '', component: () => import('@/views/Dashboard/Upload') },
  { path: '/admin/songs/:id', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Edit', component: () => import('@/views/Admin/Songs/Edit') },
  { path: '/admin/stats', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Stats', component: () => import('@/views/Admin/Stats') },
  { path: '/admin/splash', meta: { layout: AppLayoutAdmin.name, access_level: 0 }, name: 'Splash', component: () => import('@/views/Admin/Splash') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'open active',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? { x: 0, y: 0 };
  }
})

router.beforeResolve(async (to, from, next) => {
  await store.dispatch('auth/loadSession');

  // If guest page, pass along.
  if (!to.meta.hasOwnProperty('access_level')) {
    next();
    return;
  }

  // Assumed not a guest page, so making sure they are logged in.
  if (!store.getters['auth/authenticated']) {
    // They are not logged in, assume they need to login.
    next({ name: 'Login' });
    return;
  }

  // They are logged in, but need to check their access level
  if (!store.getters['auth/hasAccessLevel'](to.meta.access_level)) {
    next({path: '/'});
    return;
  }

  // Proper authentication and permissions were reached.
  next();
});

export default router
