<template>
  <app-layout id="app">
    <router-view></router-view>
  </app-layout>
</template>

<script>
import AppLayout from '@/views/AppLayout'

export default {
  components: {
    AppLayout
  }
}
</script>
