<template>
  <main>
    <AdminHeader />
    <slot />
  </main>
</template>

<script>
import AdminHeader from '@/components/Dashboard/AdminHeader'

export default {
  name: 'AppLayoutAdmin',
  components: {
    AdminHeader
  },
}
</script>
