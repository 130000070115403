import Vue from 'vue'
import Vuex, {createLogger} from 'vuex'

// Modules
import auth from './modules/auth.js'
import wavpool from './modules/wavpool.js';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    wavpool,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
