// Vue dependencies
import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'

// App dependencies
import Axios from 'axios';
import VueAxios from 'vue-axios';
// TODO: handle momentjs
import moment from 'moment-timezone';
import Vuesax from 'vuesax';
import VueTouch from 'vue-touch';
import VueSelect from 'vue-select';
import VuePusher from 'vue-pusher';
import VueEcho from 'vue-echo-laravel';
import VueScreen from 'vue-screen';
import VueCookies from 'vue-cookies';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Styles
import 'vuesax/dist/vuesax.css';
import 'vue-select/dist/vue-select.css';

import '@/styles/app.scss'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueScreen, 'bootstrap');

Vue.use(VueCookies, { expire: '30d'});

// TODO: Is there a better way to do this in vuejs?
moment.tz.setDefault(moment.tz.guess());

Vue.use(VuePusher, {
  api_key: '',
  options: {
    cluster: '',
    encrypted: true
  }
});

Vue.component('v-select', VueSelect);

Vue.use(VueTouch, { name: 'v-touch' });

Vue.use(Vuesax);

Axios.defaults.headers.common['Accept'] = 'application/json';
Axios.defaults.withCredentials = true;
Axios.defaults.baseURL = process.env.VUE_APP_API_URL + (process.env.VUE_APP_API_PROXY_URL ? '/api' : '');

Vue.use(VueAxios, Axios);

Vue.use(VueEcho, {
  broadcaster: process.env.VUE_APP_ECHO_BROADCASTER,
  key: process.env.VUE_APP_ECHO_KEY,
  cluster: process.env.VUE_APP_ECHO_CLUSTER,
  // wsHost: process.env.VUE_APP_ECHO_WS_HOST,
  // wsPort: process.env.VUE_APP_ECHO_WS_PORT,
  forceTLS: process.env.VUE_APP_ECHO_FORCE_TLS,
  disableStats: process.env.VUE_APP_ECHO_DISABLE_STATS,
  enableTransports: process.env.VUE_APP_ECHO_ENABLED_TRANSPORTS,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        this.$http
          .post('/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name
          })
          .then(response => callback(false, response.data))
          .then(error => callback(true, error));
      }
    };
  }
});

// TODO: Handle playlist created channel

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  async created() {
    this.$echo.channel('playlists')
      .listen('PlaylistCreated', (e) => {
        console.log(e);
        store.dispatch('playlists/PlaylistCreated', e.playlist);
      });
  }
}).$mount('#app')
