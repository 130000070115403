import Axios from 'axios';
import Vue from 'vue';

const auth = {
  namespaced: true,
  state: {
    authenticated: false,
    user: {}
  },
  getters: {
    authenticated: state => {
      return state.authenticated;
    },
    user: state => {
      return state.user;
    },
    isAdmin: state => {
      return state.user?.role_key === 'admin';
    },
    hasAccessLevel: state => access_level => {
      // TODO: what happens when user is {}
      return state.user?.access_level <= access_level;
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      Vue.set(state, 'user', value);
    }
  },
  actions: {
    // TODO: should Login/logout should be moved into a service class?
    async login({ dispatch }, { email, password }) {
      await Axios.get('/sanctum/csrf-cookie');

      // TODO: Reduce network requests for checking session.
      await Axios.post( '/login', { email, password });

      await dispatch('loadSession');
    },
    async logout({ dispatch }) {
      await Axios.get('/logout');
      await dispatch('clearSession')
    },
    async loadSession({dispatch, state}) {
      if (state.user.hasOwnProperty('id')) {
        return;
      }

      const user = await Axios.get('/user')
        .then(response => response.data.user)
        .catch(console.log);

      if (user) {
        await dispatch('setSession', { isAuthenticated: true, user })
      } else {
       await dispatch('clearSession')
      }
    },
    async setSession({commit}, { isAuthenticated, user }) {
      commit('SET_AUTHENTICATED', isAuthenticated);
      commit('SET_USER', user);
    },
    async clearSession({dispatch}) {
      await dispatch('setSession', { isAuthenticated: false, user: {} });
    }
  }
};

export default auth;
