<template>
  <component :is="layout">
    <slot></slot>
  </component>
</template>

<script>
import AppLayoutDefault from './AppLayoutDefault'

const defaultLayout = AppLayoutDefault.name

export default {
  name: 'AppLayout',
  computed: {
    layout () {
      const layout = this.$route.meta.layout || defaultLayout

      return () => import(`@/views/${layout}`)
    }
  },
  mounted() {
    window.addEventListener('resize', this.set100vh)

    this.$nextTick(this.set100vh)
  },
  methods: {
    set100vh() {
      document.documentElement.style.setProperty('--unit-100vh', `${window.innerHeight}px`)
    }
  }  
}
</script>
