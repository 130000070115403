<template>
  <div class="pool-header">
    <div class="pool-header__unit pool-header__unit--brand">
      <router-link
        to="/admin"
        class="pill-button"
        v-text="'.Wav Pool Admin'"
      />
    </div>

    <div class="pool-header__unit pool-header__unit--user">
      <router-link
        to="/"
        class="pill-button nav-item"
        v-text="'Pool'"
      />
      <router-link
        to="/admin/songs"
        class="pill-button nav-item"
        v-text="'Songs'"
      />
      <router-link
        to="/songs/upload"
        class="pill-button nav-item"
        v-text="'Upload'"
      />
      <router-link
        to="/admin/playlists"
        class="pill-button nav-item"
        v-text="'Playlists'"
      />
      <router-link
        to="/admin/users"
        class="pill-button nav-item"
        v-text="'Users'"
      />
      <router-link
        to="/admin/stats"
        class="pill-button nav-item"
        v-text="'Stats'"
      />
      <router-link
        to="/admin/splash"
        class="pill-button nav-item"
        v-text="'Splash'"
      />
      <a
        @click.prevent="onLogoutClick"
        href="#"
        class="pill-button nav-item"
        v-text="'Logout'"
      />
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'AdminHeader',
  methods: {
    ...mapActions('auth', ['logout']),
    onLogoutClick() {
      this.logout().then(() => {
        this.$router.push({ path: '/login' })
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.pool-header {
  pointer-events: none; /* @TODO - Place things better so we don't need to do this */
  position: fixed;
  z-index: 105;
  top: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pool-header__unit {
  pointer-events: auto;

  &.pool-header__unit--brand {
    line-height: 1.1;
    /* Off set pill padding */
    position: relative;
    top: -3px;
    left: -10px;
  }

  &.pool-header__unit--user {
    /* Off set pill padding */
    position: relative;
    top: -3px;
    right: -10px;

    .pill-button.router-link-exact-active {
      color: #76ff41;
    }
  }
}

.pill-button + .pill-button {
  margin-left: 5px;
}

.nav-item {
  margin-left: 4.3px;
  margin-right: 4.3px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
