import Axios from "axios";
import Vue from 'vue';

const getSongColor = function (colors, song) {
  const x_prime = Math.round(song.x * 100);
  const y_prime = song.y * 10;

  const x_color_index = Object.keys(colors.x).find(index => index >= x_prime);
  const y_color_index = Object.keys(colors.x[x_color_index]).find(index => index >= y_prime);

  return {
    color: colors.x[x_color_index][y_color_index],
    mood: colors.moods[x_color_index]
  };
};

const parseSong = function (state, song) {
  song.display_x = parseFloat(song.x);
  song.display_y = parseFloat(song.y);

  if (song.bpm) {
    song.display_bpm = parseFloat(song.bpm)
      .toFixed(1)
      .replace('.0', '')
  } else {
    song.display_bpm = null;
  }

  song.display_one_stop = (song.one_stop === 'true' || song.one_stop === true);

  const {color, mood} = getSongColor(state.colors, song);
  song.color = color;
  song.hex_color = `#${color}`;
  song.mood = mood;

  return song;
}

function getInitialActiveKeywordMap() {
  return {
    mood: null,
    tempo: null,
    energy: null,
    song_form: null,
    genres: []
  }
}

export default {
  namespaced: true,
  state: {
    songs: [],
    songIndexCache: {},
    activeSong: {id: null, locate: false},
    highlightedSongsIdMap: {}, // The songs that are highlighted on the canvas

    playlists: [],
    playlistIndexCache: {},
    activePlaylist: {hash: null},
    
    poolPlaylistsOpen: false,

    splashOverlayOpen: true,

    // songs.js
    colors: {},
    listenedTo: {},
    keywordGroups: {},
    keywordCoordinates: {
      'mood_angry': [1.5, null],
      'mood_serious': [3.0, null],
      'mood_sad': [5.0, null],
      'mood_hopeful': [7.0, null],
      'mood_happy': [9.0, null],

      'tempo_slow': [null, 50.0],
      'tempo_medium': [null, 85.0],
      'tempo_fast': [null, 120.0],
      'tempo_very_fast': [null, 170.0]
    },

    // customPlaylist
    customPlaylistActiveKeywordMap: getInitialActiveKeywordMap(),
    // These are flags so we know which keyword groups have been interacted with
    customPlaylistMoodInteracted:  false,
    customPlaylistTempoInteracted: false,
    customPlaylistNonMoodTempoKeywordInteracted: false
  },
  getters: {
    songs: state => {
      return state.songs;
    },
    activeSong: state => {
      if (state.activeSong.id === null) {
        return null;
      }

      return { ...state.songs[state.songIndexCache[state.activeSong.id]], locate: state.activeSong.locate };
    },
    activePlaylist: state => {
      if (state.activePlaylist.hash === null) {
        return null;
      }

      return state.playlists[state.playlistIndexCache[state.activePlaylist.hash]]
    },
    activeSongId: state => {
      return state.activeSong.id;
    },
    activePlaylistHash: state => {
      return state.activePlaylist.hash;
    },

    // songs.js
    colors: state => {
      return state.colors;
    },
    listenedTo: state => {
      return state.listenedTo;
    },

    // playlists.js
    publicPlaylists: state => {
      return state.playlists.filter(playlist => playlist.status === 'public');
    },
    artistPlaylists: state => {
      return state.playlists.filter(playlist => playlist.status === 'artist');
    },
    userPlaylists: state => {
      return state.playlists.filter(playlist => playlist.status === 'private');
    },
    editablePlaylists(state, getters, rootState) {
      let playlists = getters.userPlaylists;

      if (rootState.auth.isAdmin) {
        playlists.concat(getters.publicPlaylists);
      }

      return playlists;
    },
    getPlaylistByHash: state => hash => {
      return state.playlists.find(p => p.hash === hash)
    },

    customPlaylistActiveKeywordIds: state => {
      const kMap = state.customPlaylistActiveKeywordMap

      return [
        kMap['mood'],
        kMap['tempo'],
        kMap['energy'],
        kMap['song_form'],
        ...kMap['genres']
      ].filter(Boolean).map(k => k.id)
    },

    customPlaylistActiveKeywordMood: state => state.customPlaylistActiveKeywordMap.mood,
    customPlaylistActiveKeywordTempo: state => state.customPlaylistActiveKeywordMap.tempo
  },
  mutations: {
    LOAD_SONGS(state, songs) {
      Vue.set(state, 'songs', songs);

      const indexes = {};

      state.songs.forEach((song, index) => {
        indexes[song.id] = index;
      });

      Vue.set(state, 'songIndexCache', indexes);
    },
    LOAD_SONG(state, song) {
      let songIndex = state.songs.findIndex(s => s.id === song.id);

      if (songIndex === -1) {
        state.songs.push(song);
        songIndex = state.songs.length - 1;
      }

      Vue.set(state.songIndexCache, song.id, songIndex);
    },
    SELECTED_SONG(state, {id, locate}) {
      state.activeSong.id = id;
      state.activeSong.locate = locate;
    },
    SELECTED_PLAYLIST(state, {hash}) {
      state.activePlaylist.hash = hash;
    },
    SET_HIGHLIGHTED_SONGS(state, songs = []) {
      const map = songs.reduce((acc, { id }) => {
        acc[id] = true

        return acc
      }, {})

      Vue.set(state, 'highlightedSongsIdMap', map)
    },

    // songs.js
    SET_COLORS(state, colors) {
      Vue.set(state, 'colors', colors);
    },
    SET_LISTENED_TO(state, listenedTo) {
      Vue.set(state, 'listenedTo', listenedTo);
    },
    ADD_LISTENED_TO(state, songId) {
      Vue.set(state.listenedTo, songId, true);
    },
    SET_KEYWORD_GROUPS(state, keywordGroups) {
      Vue.set(state, 'keywordGroups', keywordGroups);
    },

    // playlists.js
    ADD_PLAYLIST(state, playlist) {
      let foundIndex = state.playlists.findIndex(p => p.hash === playlist.hash);

      if (foundIndex >= 0) {
        Vue.set(state.playlists, foundIndex, playlist);
      } else {
        state.playlists.push(playlist);
        foundIndex = state.playlists.length - 1;
      }

      Vue.set(state.playlistIndexCache, playlist.hash, foundIndex);
    },
    REMOVE_PLAYLIST(state, playlist) {
      let foundIndex = state.playlists.findIndex(p => p.hash === playlist.hash);

      if (foundIndex >= 0) {
        Vue.delete(state.playlists, foundIndex);
        Vue.delete(state.playlistIndexCache, playlist.hash);
      }
    },
    SET_PLAYLISTS(state, playlists) {
      Vue.set(state, 'playlists', playlists);

      const playlistIndexCache = {};

      state.playlists.forEach((playlist, index) => {
        playlistIndexCache[playlist.hash] = index;
      });

      Vue.set(state, 'playlistIndexCache', playlistIndexCache);
    },

    SET_CUSTOM_PLAYLIST_ACTIVE_KEYWORD_MAP_VALUE(state, {type, value}) {
      Vue.set(state.customPlaylistActiveKeywordMap, type, value);
    },
    RESET_CUSTOM_PLAYLIST_ACTIVE_KEYWORD_MAP(state) {
      Vue.set(state, 'customPlaylistActiveKeywordMap', getInitialActiveKeywordMap());
    },
    SET_CUSTOM_PLAYLIST_TEMPO_INTERACTED(state, value = true) {
      Vue.set(state, 'customPlaylistTempoInteracted', value);
    },
    SET_CUSTOM_PLAYLIST_MOOD_INTERACTED(state, value = true) {
      Vue.set(state, 'customPlaylistMoodInteracted', value);
    },
    SET_CUSTOM_PLAYLIST_NON_MOOD_TEMPO_KEYWORD_INTERACTED(state, value = true) {
      Vue.set(state, 'customPlaylistNonMoodTempoKeywordInteracted', value);
    },
    SET_POOL_PLAYLISTS_OPEN(state, value = false) {
      Vue.set(state, 'poolPlaylistsOpen', value);
    },
    SET_SPLASH_OVERLAY_OPEN(state, value = false) {
      Vue.set(state, 'splashOverlayOpen', value);
    }
  },
  actions: {
    async SelectSong({commit, dispatch, state, rootState}, song) {
      if (state.activeSongId === song.id) {
        return;
      }

      await dispatch('LoadSong', song);

      commit('SELECTED_SONG', {
        id: song.id,
        locate: song.hasOwnProperty('locate')
      });

      await dispatch('MarkSongListenedTo', song.id);
    },
    async DeselectSong({commit}) {
      commit('SELECTED_SONG', {id: null});
    },
    async SelectPlaylist({commit, dispatch, state}, hash) {
      await dispatch('LoadPlaylist', hash);

      await commit('SELECTED_PLAYLIST', {hash: hash});

      commit('SET_HIGHLIGHTED_SONGS', state.playlists[state.playlistIndexCache[state.activePlaylist.hash]]?.songs)
    },
    async DeselectPlaylist({commit, dispatch, state}) {
      if (state.activePlaylist.hash === null) return

      commit('SELECTED_PLAYLIST', {hash: null})
      dispatch('ResetHighlightedSongs')
    },
    async SelectPlaylistSong({commit, dispatch}, songId) {
      await dispatch('SelectSong', {id: songId, locate: true});
    },
    ResetHighlightedSongs({commit}) {
      commit('SET_HIGHLIGHTED_SONGS', [])
    },

    // songs.js
    async LoadSongs({commit, state}) {
      commit(
        'LOAD_SONGS',
        await Axios.get('/pool-chunk')
          .then(response => response.data.songs)
          .catch(console.log)
          .then(songs => {
            songs.map(s => parseSong(state, s));
            return songs;
          })
          .then(Object.freeze)
      )
    },
    async LoadSong({commit, state}, song) {
      if (! state.songIndexCache.hasOwnProperty(song.id)) {
        commit(
          'LOAD_SONG',
          await Axios.get(`/pool/song/${song.id}`)
            .then(response => response.data.song)
            .catch(console.log)
            .then(s => parseSong(state, s))
            .then(Object.freeze)
        )
      }
    },
    async LoadColors({commit}) {
      const colorData = await Axios.get('/colors')
        .then(response => response.data)
        .then(Object.freeze);

      commit('SET_COLORS', colorData)
    },
    async LoadListened({commit, rootState}) {
      let listenedToData = {};

      if (rootState.auth.user.hasOwnProperty('id')) {
        listenedToData = await Axios.get('/songs/listened')
          .then(response => response.data.listened_to)
          .then(listenedTo => {
            for(const key in listenedTo) {
              listenedTo[key] = true;
            }

            return listenedTo;
          })
          .then(Object.freeze);
      }

      commit('SET_LISTENED_TO', listenedToData);
    },
    async MarkSongListenedTo({commit, rootState}, id) {
      if (rootState.auth.user.hasOwnProperty('id')) {
        await Axios.get(`/songs/listened/${id}`);
      }

      commit('ADD_LISTENED_TO', id);
    },
    async LoadKeywordGroups({commit}) {
      const keywordGroups = await Axios.get('/keywords')
        .then(response => response.data)
        .then(Object.freeze);

      commit('SET_KEYWORD_GROUPS', keywordGroups)
    },

    // playlists.js
    async AddPlaylist({commit, state}, {hash}) {
      commit(
        'ADD_PLAYLIST',
        await Axios.get(`/pool-chunk-playlist/${hash}`)
          .then(response => response.data.playlist)
          .then(playlist => {
            playlist.songs.map(s => {
              const parsedSong = parseSong(state, s);
              Object.freeze(parsedSong);
              return parsedSong;
            });
            return playlist;
          })
      );
    },
    async RemovePlaylist({commit}, hash) {
      commit('REMOVE_PLAYLIST', {hash});
    },
    async ReloadPlaylist({dispatch}, hash) {
      await dispatch('AddPlaylist', {hash});
    },
    async LoadPlaylist({state, commit, rootState}, hash) {
      if (state.playlistIndexCache.hasOwnProperty(hash)) {
        return;
      }

      const playlist = await Axios.get(`/pool-chunk-playlist/${hash}`)
        .then(response => response.data.playlist)
        .catch(console.log)
        .then(playlist => {
          playlist.songs.map(s => parseSong(state, s));
          return playlist;
        });

      // If we're loading a private playlist that doesn't belong to the user... (sharing a private playlist with a non-logged in user)
      // Merge it with the user's private playlists
      if (
        playlist.status === 'private'
        && playlist.user_id !== rootState.auth.user.id
      ) {
        commit('ADD_PLAYLIST', playlist);
      } else {
        // it's private and belongs to the user so it will get loaded with user playlists
        // or it's public and will show up in `publicPlaylists`
      }
    },
    async LoadAllPlaylists({commit, state, rootState, getters}) {
      let playlists = await Axios.get(`/public/playlists`)
        .then(response => response.data.playlists)
        .then(playlists => {
          return playlists.map(playlist => {
            playlist.songs.map(s => parseSong(state, s));

            return playlist;
          });
        });

      if (rootState.auth.user.hasOwnProperty('id')) {
        const privatePlaylists = await Axios.get(`/user/${rootState.auth.user.id}/playlists`)
          .then(response => response.data.playlists)
          .catch(console.log)
          .then(playlists => {
            return playlists.map(playlist => {
              playlist.songs.map(s => parseSong(state, s));

              return playlist;
            });
          });

        playlists = [...playlists, ...privatePlaylists];
      }

      if (getters.activePlaylistHash) {
        const playlist = await Axios.get(`/pool-chunk-playlist/${getters.activePlaylistHash}`)
          .then(response => response.data.playlist)
          .catch(console.log)
          .then(playlist => {
            playlist.songs.map(s => parseSong(state, s));
            return playlist;
          });

        playlists.push(playlist);
      }

      commit('SET_PLAYLISTS', playlists);
    },
    async CustomPlaylistToggleKeywordActive({commit, state}, {type, keyword}) {
      const mapValue = state.customPlaylistActiveKeywordMap[type];
      let newMapValue;
      let interactedMutation = ''

      // If it's an array, it works like a multi select
      if (Array.isArray(mapValue)) {
        newMapValue = [...mapValue] // need to dupe it to avoid 'do not mutate vuex store state outside mutation handlers' error
        const index = mapValue.findIndex(k => k.id === keyword.id)

        index > -1 ? newMapValue.splice(index, 1) : newMapValue.push(keyword)
      }
      // Otherwise, it works like a radio button
      else {
        newMapValue = (mapValue === keyword ? null : keyword)
      }

      // If they've selected a keyword, mark it as interacted
      if (keyword) {
        if (type === 'mood') {
          interactedMutation = 'SET_CUSTOM_PLAYLIST_MOOD_INTERACTED'
        }
        else if (type === 'tempo') {
          interactedMutation = 'SET_CUSTOM_PLAYLIST_TEMPO_INTERACTED'
        }
        else {
          interactedMutation = 'SET_CUSTOM_PLAYLIST_NON_MOOD_TEMPO_KEYWORD_INTERACTED'
        }
      }

      commit(interactedMutation, true)
      commit('SET_CUSTOM_PLAYLIST_ACTIVE_KEYWORD_MAP_VALUE', ({type, value: newMapValue}))
    },
    CustomPlaylistReset({commit}) {
      commit('RESET_CUSTOM_PLAYLIST_ACTIVE_KEYWORD_MAP')
      commit('SET_CUSTOM_PLAYLIST_TEMPO_INTERACTED', false)
      commit('SET_CUSTOM_PLAYLIST_MOOD_INTERACTED', false)
      commit('SET_CUSTOM_PLAYLIST_NON_MOOD_TEMPO_KEYWORD_INTERACTED', false)
    },
    TogglePoolPlaylistsOpen({commit, state}) {
      commit('SET_POOL_PLAYLISTS_OPEN', !state.poolPlaylistsOpen)
    }
  }
};
